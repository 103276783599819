<template>
  <CommonRippleBox
    @click="onClick(serviceList)"
    class="relative flex items-start p-5"
    ripple-position="top-left"
  >
    <div class="flex h-11 w-11 flex-none items-start justify-center">
      <NuxtImg
        loading="lazy"
        :src="clinicIcon"
        class="w-9"
        alt="clinic icon"
        width="36"
        height="36"
      />
    </div>
    <div class="flex flex-col mt-1 mr-2 md:mx-3">
      <div class="font-bold">
        {{ serviceList.title }}
      </div>
      <div class="my-2 text-sm leading-6 md:mr-8 text-left">
        {{ serviceList.short_description }}
      </div>
    </div>
    <img
      class="ml-auto"
      src="/icons/mask.svg"
      alt="mask icon"
      width="30"
      height="30"
    />
  </CommonRippleBox>
</template>
<script setup>
const props = defineProps({
  serviceList: {
    type: Object,
    default: {},
  }
});
const clinicIcon = computed(() => {
  return props.serviceList.slug === "instant-test"
    ? "/icons/clinic-instant-tests-icon-green.svg"
    :props.serviceList.icon.permalink;
});

const emit = defineEmits(["onClicked"]);
function onClick(value) {
  emit("onClicked", value);
}
</script>
